import { openDB } from 'idb';

const DB_NAME = 'AuthDB';
const DB_VERSION = 1;
const ADMIN_STORE = 'adminCredentials';

interface AdminCredentials {
  email: string;
  passwordHash: string;
}

async function initAuthDB() {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      db.createObjectStore(ADMIN_STORE, { keyPath: 'email' });
    },
  });

  // Check if admin credentials exist, if not, create them
  const tx = db.transaction(ADMIN_STORE, 'readwrite');
  const store = tx.objectStore(ADMIN_STORE);
  const existingAdmin = await store.get('camisssounds@gmail.com');

  if (!existingAdmin) {
    // In a real-world scenario, you'd use a proper hashing method
    const passwordHash = btoa('Manuka!2'); // This is NOT secure, just for demonstration
    await store.add({ email: 'camisssounds@gmail.com', passwordHash });
  }

  await tx.done;
}

export async function login(email: string, password: string): Promise<string | null> {
  await initAuthDB();
  const db = await openDB(DB_NAME, DB_VERSION);
  const admin = await db.get(ADMIN_STORE, email);

  if (admin && btoa(password) === admin.passwordHash) {
    // Generate a simple token (in a real app, use a proper JWT library)
    return btoa(email + ':' + Date.now());
  }

  return null;
}

export function verifyToken(token: string): boolean {
  try {
    const [email, timestamp] = atob(token).split(':');
    const now = Date.now();
    const tokenAge = now - parseInt(timestamp);
    // Token is valid for 1 hour
    return email === 'camisssounds@gmail.com' && tokenAge < 3600000;
  } catch (error) {
    return false;
  }
}