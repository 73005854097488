import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

function Footer() {
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);

  const toggleDisclaimer = () => setDisclaimerOpen(!disclaimerOpen);
  const togglePrivacy = () => setPrivacyOpen(!privacyOpen);

  return (
    <footer className="bg-[#092144] text-white py-6">
      <div className="container mx-auto px-4">
        <div className="mb-4">
          <button
            onClick={toggleDisclaimer}
            className="flex justify-between items-center w-full py-2 px-4 bg-[#0c3166] hover:bg-[#1c4176] transition-colors rounded"
          >
            <span>Disclaimer</span>
            {disclaimerOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </button>
          {disclaimerOpen && (
            <div className="mt-2 p-4 bg-[#1c4176] rounded">
              <p className="text-sm md:text-base">
                Disclaimer: The information provided by this application is for general informational purposes only. We are not responsible for any financial decisions or problems that may arise as a result of using this app. Always consult with a qualified financial advisor before making any financial decisions.
              </p>
            </div>
          )}
        </div>
        <div className="mb-4">
          <button
            onClick={togglePrivacy}
            className="flex justify-between items-center w-full py-2 px-4 bg-[#0c3166] hover:bg-[#1c4176] transition-colors rounded"
          >
            <span>Privacy Policy</span>
            {privacyOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </button>
          {privacyOpen && (
            <div className="mt-2 p-4 bg-[#1c4176] rounded">
              <p className="text-sm md:text-base">
                Privacy Policy: This application collects and stores data necessary for its functionality, including exchange rates and user preferences. We use cookies to enhance user experience and analyze app usage. All data is stored locally on your device and is not shared with third parties. By using this application, you consent to our data collection and usage practices.
              </p>
            </div>
          )}
        </div>
        <div className="text-center text-xs md:text-sm mt-6">
          © 2024 USD to SSP Exchange Rate Tracker. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;