import React, { useState, useEffect } from 'react';
import { Trash2, Plus, Upload, LogOut } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getAllLocations, addLocation, deleteLocation, updateLogo } from '../utils/db';

interface ExchangeData {
  id: string;
  date: string;
  rate: number;
}

interface Location {
  id?: number;
  name: string;
}

function AdminPanel() {
  const [exchangeRates, setExchangeRates] = useState<ExchangeData[]>([]);
  const [newRate, setNewRate] = useState({ date: '', rate: '' });
  const [locations, setLocations] = useState<Location[]>([]);
  const [newLocation, setNewLocation] = useState('');
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      const response = await fetch('/api/exchangeRates');
      if (!response.ok) {
        throw new Error('Failed to fetch exchange rates');
      }
      const rates = await response.json();
      setExchangeRates(rates);
      const locs = await getAllLocations();
      setLocations(locs);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }

  const handleAddRate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newRate.date && newRate.rate) {
      try {
        const response = await fetch('/api/exchangeRates', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ date: newRate.date, rate: parseFloat(newRate.rate) }),
        });
        if (!response.ok) {
          throw new Error('Failed to add new rate');
        }
        setNewRate({ date: '', rate: '' });
        loadData();
      } catch (error) {
        console.error('Error adding new rate:', error);
      }
    }
  };

  const handleDeleteRate = async (id: string) => {
    try {
      const response = await fetch(`/api/exchangeRates/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete rate');
      }
      loadData();
    } catch (error) {
      console.error('Error deleting rate:', error);
    }
  };

  const handleAddLocation = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newLocation) {
      await addLocation(newLocation);
      setNewLocation('');
      loadData();
    }
  };

  const handleDeleteLocation = async (id: number) => {
    await deleteLocation(id);
    loadData();
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogoFile(e.target.files[0]);
    }
  };

  const handleUpdateLogo = async (e: React.FormEvent) => {
    e.preventDefault();
    if (logoFile) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result as string;
        await updateLogo(base64data);
        alert('Logo updated successfully!');
      };
      reader.readAsDataURL(logoFile);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/login');
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-6 text-[#092144]">Admin Panel</h1>
      <button onClick={handleLogout} className="mb-4 bg-red-500 text-white p-2 rounded flex items-center hover:bg-red-600 transition-colors">
        <LogOut className="mr-2" /> Logout
      </button>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4 text-[#092144]">Manage Exchange Rates</h2>
        <form onSubmit={handleAddRate} className="mb-4">
          <input
            type="date"
            value={newRate.date}
            onChange={(e) => setNewRate({ ...newRate, date: e.target.value })}
            className="border p-2 mr-2 rounded"
          />
          <input
            type="number"
            value={newRate.rate}
            onChange={(e) => setNewRate({ ...newRate, rate: e.target.value })}
            placeholder="Rate"
            className="border p-2 mr-2 rounded"
          />
          <button type="submit" className="bg-[#092144] text-white p-2 rounded flex items-center hover:bg-[#0c3166] transition-colors">
            <Plus className="inline mr-1" /> Add Rate
          </button>
        </form>
        <ul>
          {exchangeRates.map((rate) => (
            <li key={rate.id} className="flex items-center justify-between mb-2">
              <span>{rate.date}: {rate.rate} SSP</span>
              <button onClick={() => handleDeleteRate(rate.id)} className="text-red-500 hover:text-red-600 transition-colors">
                <Trash2 />
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4 text-[#092144]">Manage Top Locations</h2>
        <form onSubmit={handleAddLocation} className="mb-4">
          <input
            type="text"
            value={newLocation}
            onChange={(e) => setNewLocation(e.target.value)}
            placeholder="Location name"
            className="border p-2 mr-2 rounded"
          />
          <button type="submit" className="bg-[#092144] text-white p-2 rounded flex items-center hover:bg-[#0c3166] transition-colors">
            <Plus className="inline mr-1" /> Add Location
          </button>
        </form>
        <ul>
          {locations.map((location) => (
            <li key={location.id} className="flex items-center justify-between mb-2">
              <span>{location.name}</span>
              <button onClick={() => location.id && handleDeleteLocation(location.id)} className="text-red-500 hover:text-red-600 transition-colors">
                <Trash2 />
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h2 className="text-2xl font-bold mb-4 text-[#092144]">Update Logo</h2>
        <form onSubmit={handleUpdateLogo} className="mb-4">
          <input
            type="file"
            accept="image/*"
            onChange={handleLogoChange}
            className="border p-2 mr-2 rounded w-full mb-2"
          />
          <button type="submit" className="bg-[#092144] text-white p-2 rounded flex items-center hover:bg-[#0c3166] transition-colors">
            <Upload className="inline mr-1" /> Update Logo
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminPanel;