import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import ExchangeRateTracker from './components/ExchangeRateTracker';
import AdminPanel from './components/AdminPanel';
import Login from './components/Login';
import Footer from './components/Footer';
import { verifyToken } from './utils/auth';
import SEOMetadata from './components/SEOMetadata';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const token = localStorage.getItem('adminToken');
  if (!token || !verifyToken(token)) {
    return <Navigate to="/login" replace />;
  }
  return <>{children}</>;
}

function App() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "USD to SSP Exchange Rate Tracker",
    "description": "Track real-time exchange rates for USD to SSP (South Sudanese Pound). Get up-to-date information on Juba exchange rates, Bank of South Sudan rates, and SSP to USD conversions.",
    "url": "https://your-domain.com",
    "applicationCategory": "FinanceApplication",
    "operatingSystem": "All"
  };

  return (
    <Router>
      <SEOMetadata structuredData={structuredData} />
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <header>
          <nav className="bg-[#092144] text-white p-4">
            <ul className="flex space-x-4 justify-center">
              <li>
                <Link to="/" className="hover:text-[#279b48] transition-colors">Exchange Rate Tracker</Link>
              </li>
              <li>
                <Link to="/admin" className="hover:text-[#279b48] transition-colors">Admin Panel</Link>
              </li>
            </ul>
          </nav>
        </header>

        <main className="flex-grow container mx-auto p-4">
          <Routes>
            <Route path="/" element={<ExchangeRateTracker />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            } />
          </Routes>
        </main>

        <Footer />
      </div>
    </Router>
  );
}

export default App;