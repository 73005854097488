import { openDB, DBSchema, IDBPDatabase } from 'idb';

interface CurrencyExchangeDB extends DBSchema {
  topLocations: {
    key: number;
    value: {
      id?: number;
      name: string;
    };
  };
  appLogo: {
    key: number;
    value: {
      id?: number;
      data: string;
    };
  };
}

const DB_NAME = 'CurrencyExchangeDB';
const DB_VERSION = 3;

let dbInstance: IDBPDatabase<CurrencyExchangeDB> | null = null;

export async function initializeDB(): Promise<IDBPDatabase<CurrencyExchangeDB>> {
  if (dbInstance) {
    console.log('Database instance already exists, returning...');
    return dbInstance;
  }

  try {
    console.log('Opening database...');
    dbInstance = await openDB<CurrencyExchangeDB>(DB_NAME, DB_VERSION, {
      upgrade(db, oldVersion, newVersion, transaction) {
        console.log(`Upgrading database from version ${oldVersion} to ${newVersion}`);
        
        if (!db.objectStoreNames.contains('topLocations')) {
          console.log('Creating topLocations object store');
          db.createObjectStore('topLocations', { keyPath: 'id', autoIncrement: true });
        }
        
        if (!db.objectStoreNames.contains('appLogo')) {
          console.log('Creating appLogo object store');
          db.createObjectStore('appLogo', { keyPath: 'id', autoIncrement: true });
        } else if (oldVersion < 3) {
          // Migrate the appLogo store to use base64 data instead of URL
          const appLogoStore = transaction.objectStore('appLogo');
          appLogoStore.clear();
        }
      },
    });

    console.log('Database opened successfully');
    return dbInstance;
  } catch (error) {
    console.error('Error initializing database:', error);
    throw error;
  }
}

export async function getAllLocations() {
  const db = await initializeDB();
  return db.getAll('topLocations');
}

export async function addLocation(name: string) {
  const db = await initializeDB();
  return db.add('topLocations', { name });
}

export async function deleteLocation(id: number) {
  const db = await initializeDB();
  return db.delete('topLocations', id);
}

export async function getLogo() {
  const db = await initializeDB();
  const logos = await db.getAll('appLogo');
  return logos.length > 0 ? logos[0].data : null;
}

export async function updateLogo(data: string) {
  const db = await initializeDB();
  await db.clear('appLogo');
  return db.add('appLogo', { data });
}