import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { DollarSign, RefreshCcw, Download } from 'lucide-react';
import * as XLSX from 'xlsx';
import { getAllLocations, getLogo } from '../utils/db';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ExchangeData {
  id: string;
  date: string;
  rate: number;
}

interface Location {
  id?: number;
  name: string;
}

function ExchangeRateTracker() {
  const [exchangeRates, setExchangeRates] = useState<ExchangeData[]>([]);
  const [newRate, setNewRate] = useState('');
  const [locations, setLocations] = useState<Location[]>([]);
  const [logo, setLogo] = useState<string | null>(null);
  const [dailyAverage, setDailyAverage] = useState<number | null>(null);
  const [weeklyAverage, setWeeklyAverage] = useState<number | null>(null);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    try {
      const response = await fetch('/api/exchangeRates');
      if (!response.ok) {
        throw new Error('Failed to fetch exchange rates');
      }
      const rates = await response.json();
      setExchangeRates(rates);
      calculateAverages(rates);

      const locations = await getAllLocations();
      setLocations(locations);
      const logoData = await getLogo();
      setLogo(logoData);
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }

  const calculateAverages = (rates: ExchangeData[]) => {
    const today = new Date().toISOString().split('T')[0];
    const oneWeekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];

    const todayRates = rates.filter(rate => rate.date === today);
    const weekRates = rates.filter(rate => rate.date >= oneWeekAgo);

    if (todayRates.length > 0) {
      const dailyAvg = todayRates.reduce((sum, rate) => sum + rate.rate, 0) / todayRates.length;
      setDailyAverage(parseFloat(dailyAvg.toFixed(2)));
    } else {
      setDailyAverage(null);
    }

    if (weekRates.length > 0) {
      const weeklyAvg = weekRates.reduce((sum, rate) => sum + rate.rate, 0) / weekRates.length;
      setWeeklyAverage(parseFloat(weeklyAvg.toFixed(2)));
    } else {
      setWeeklyAverage(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const rate = parseFloat(newRate);
    if (!isNaN(rate)) {
      const newData = { date: new Date().toISOString().split('T')[0], rate };
      try {
        const response = await fetch('/api/exchangeRates', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newData),
        });
        if (!response.ok) {
          throw new Error('Failed to add new rate');
        }
        setNewRate('');
        loadData();
      } catch (error) {
        console.error('Error adding new rate:', error);
      }
    }
  };

  const chartData = {
    labels: exchangeRates.map(data => data.date),
    datasets: [
      {
        label: 'Exchange Rate (SSP per 100 USD)',
        data: exchangeRates.map(data => data.rate),
        borderColor: '#279b48',
        backgroundColor: '#092144',
        tension: 0.1
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'USD to SSP Exchange Rate',
      },
    },
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(exchangeRates);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Exchange Rates");
    XLSX.writeFile(wb, "exchange_rates.xlsx");
  };

  return (
    <div className="container mx-auto p-4">
      {logo && <img src={logo} alt="App Logo" className="w-32 h-32 mx-auto mb-4" />}
      <h1 className="text-3xl font-bold text-center mb-6 text-[#092144]">USD to SSP Exchange Rate Tracker</h1>
      
      <form onSubmit={handleSubmit} className="mb-6">
        <div className="flex items-center justify-center">
          <DollarSign className="text-[#279b48] mr-2" />
          <input
            type="number"
            value={newRate}
            onChange={(e) => setNewRate(e.target.value)}
            placeholder="Enter rate for 100 USD"
            className="border p-2 mr-2 rounded w-64 text-sm"
          />
          <button type="submit" className="bg-[#092144] text-white p-2 rounded flex items-center hover:bg-[#0c3166] transition-colors">
            <RefreshCcw className="mr-2" /> Add Rate
          </button>
        </div>
      </form>

      <div className="mb-6">
        <Line data={chartData} options={chartOptions} />
      </div>

      <div className="mb-6 text-center">
        <h2 className="text-2xl font-bold mb-2 text-[#092144]">Average Rates</h2>
        <p className="text-lg">Daily Average: {dailyAverage ? `${dailyAverage} SSP` : 'N/A'}</p>
        <p className="text-lg">Weekly Average: {weeklyAverage ? `${weeklyAverage} SSP` : 'N/A'}</p>
      </div>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-2 text-[#092144]">Top 5 Exchange Locations</h2>
        <ul className="list-disc pl-5">
          {locations.slice(0, 5).map((location) => (
            <li key={location.id}>{location.name}</li>
          ))}
        </ul>
      </div>

      <button onClick={downloadExcel} className="bg-[#279b48] text-white p-2 rounded flex items-center mx-auto hover:bg-[#2eb555] transition-colors">
        <Download className="mr-2" /> Download Excel
      </button>
    </div>
  );
}

export default ExchangeRateTracker;