import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import './index.css';
import { initializeDB } from './utils/db';

console.log('main.tsx is being executed');

function ErrorBoundary({ children }: { children: React.ReactNode }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    console.log('ErrorBoundary mounted');
    const errorHandler = (error: ErrorEvent) => {
      console.error('Uncaught error:', error);
      setHasError(true);
    };

    window.addEventListener('error', errorHandler);
    return () => window.removeEventListener('error', errorHandler);
  }, []);

  if (hasError) {
    return <h1>Something went wrong. Please refresh the page.</h1>;
  }

  return <>{children}</>;
}

function Root() {
  console.log('Root component rendering');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log('Root component mounted');
    const init = async () => {
      try {
        console.log('Initializing database...');
        await initializeDB();
        console.log('Database initialized successfully');
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to initialize database:", error);
        setError("Failed to initialize the application. Please try refreshing the page or clearing your browser data.");
        setIsLoading(false);
      }
    };

    init();
  }, []);

  if (isLoading) {
    console.log('Rendering loading state');
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    console.log('Rendering error state');
    return <div className="error">{error}</div>;
  }

  console.log('Rendering App component');
  return (
    <HelmetProvider>
      <App />
    </HelmetProvider>
  );
}

console.log('Attempting to render Root component');
const rootElement = document.getElementById('root');
console.log('Root element:', rootElement);

if (rootElement) {
  try {
    ReactDOM.createRoot(rootElement).render(
      <React.StrictMode>
        <ErrorBoundary>
          <Root />
        </ErrorBoundary>
      </React.StrictMode>
    );
    console.log('Render attempt completed successfully');
  } catch (error) {
    console.error('Error during rendering:', error);
  }
} else {
  console.error('Root element not found');
}