import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOMetadataProps {
  title?: string;
  description?: string;
  canonicalUrl?: string;
  structuredData?: object;
}

const SEOMetadata: React.FC<SEOMetadataProps> = ({ 
  title = "USD to SSP Exchange Rate Tracker | Juba Exchange Rates",
  description = "Track real-time exchange rates for USD to SSP (South Sudanese Pound). Get up-to-date information on Juba exchange rates, Bank of South Sudan rates, and SSP to USD conversions.",
  canonicalUrl = "https://your-domain.com",
  structuredData
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content="Juba, exchange rates South Sudan, Juba SSP, SSP to USD, Bank of South Sudan, South Sudanese Pound, currency converter, forex rates Juba" />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
    </Helmet>
  );
};

export default SEOMetadata;